<template>
  <div id="app">
    <div class="flex-grow-1 me-3">
      <div class="d-flex align-items-center">
        <div class="input-group me-2">
          <VueDatePicker v-model="date" range :preset-dates="presetDates" :multi-calendars="{ solo: true }" :enable-time-picker="false" select-text="Selecteer" cancel-text="Sluiten">
            <template #preset-date-range-button="{ label, value, presetDate }">
              <span
                role="button"
                :tabindex="0"
                @click="presetDate(value)"
                @keyup.enter.prevent="presetDate(value)"
                @keyup.space.prevent="presetDate(value)">
                {{ label }}
              </span>
            </template>
          </VueDatePicker>
        </div>
        <div class="form-check me-2">
          <input class="form-check-input" type="checkbox" id="archivedCheckbox" v-model="showArchived">
          <label class="form-check-label" for="archivedCheckbox">
            Toon gearchiveerde kansen
          </label>
        </div>
        <button class="btn btn-outline-primary me-2" type="button" @click="fetchColumns">Zoek</button>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="min-h-screen flex overflow-x-scroll py-6">
        <div
          v-for="column in columns"
          :key="column.id"
          :data-stage-id="column.id"
          class="bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-4">

          <div class="row mb-5 justify-content-between">
            <div class="col-auto">
              <span class="text-gray-700 font-semibold font-sans tracking-wide text-sm">{{ column.name }}</span>
            </div>
            <div class="col-auto">
              <span class="addTask"><a :href="`/portal/sale/opportunity/${column.id}/new`"><i class="fa-solid fa-square-plus"></i></a></span>
            </div>
          </div>

          <draggable
            :list="column.tasks"
            :group="{ name: 'tasks', pull: true, put: true }"
            @end="onDragEnd"
            :animation="200"
            ghost-class="ghost-card"
          >
            <template #item="{ element }">
              <task-card
                :task="element"
                :type="type"
                class="mt-5 cursor-move"
              ></task-card>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import TaskCard from './TaskCard.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, onMounted } from 'vue';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';

export default {
  components: {
    draggable,
    TaskCard,
    VueDatePicker
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup() {
    const date = ref([subMonths(new Date(), 3), new Date()]);
    const showArchived = ref(false);

    const presetDates = ref([
      { label: 'Dit jaar', value: [startOfYear(new Date()), endOfYear(new Date())] },
      { label: 'Deze maand', value: [startOfMonth(new Date()), endOfMonth(new Date())] },
      { label: 'Vorige maand', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
      { label: 'Afgelopen 3 maanden', value: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(new Date())] },
      { label: 'Afgelopen 6 maanden', value: [startOfMonth(subMonths(new Date(), 6)), endOfMonth(new Date())] },
      { label: 'Vorig jaar', value: [startOfYear(subMonths(new Date(), 12)), endOfYear(subMonths(new Date(), 12))] },
    ]);

    return {
      date,
      presetDates,
      showArchived
    };
  },
  data() {
    return {
      columns: [],
      newStageId: null,
    };
  },
  created() {
    this.fetchColumns();
  },
  methods: {
    async fetchColumns() {
      try {
        let stagesUrl = '';
        let tasksUrl = '';

        if (this.type === 'opportunity') {
          stagesUrl = '/portal/sale/opportunity/stages';
          tasksUrl = '/portal/sale/opportunity/salesOpportunities';
        } else if (this.type === 'project') {
          stagesUrl = '/portal/project/stages';
          tasksUrl = '/portal/project/tasks';
        }

        const stagesResponse = await axios.get(stagesUrl);

        const searchParams = {
          startDate: this.date[0],
          endDate: this.date[1],
          archive: this.showArchived
        };

        const tasksResponse = await axios.post(tasksUrl, searchParams);

        if (stagesResponse.data && Array.isArray(stagesResponse.data.stages)) {
          // Initialize columns with stages
          this.columns = stagesResponse.data.stages.map(stage => ({
            ...stage,
            tasks: []
          }));

          // Add tasks to the appropriate columns based on stageId
          if (tasksResponse.data && Array.isArray(tasksResponse.data)) {
            tasksResponse.data.forEach(task => {
              const column = this.columns.find(col => col.id === task.stageId);
              if (column) {
                column.tasks.push(task);
              }
            });
          } else {
            console.error('Unexpected tasks response format:', tasksResponse.data);
          }
        } else {
          console.error('Unexpected stages response format:', stagesResponse.data);
        }
      } catch (error) {
        console.error('Er is een fout opgetreden bij het ophalen van de kolommen:', error);
      }
    },
    async onDragEnd(event) {
      const task = event.item._underlying_vm_;
      const newStageId = event.to.parentNode.dataset.stageId; // Haal de stageId van de nieuwe kolom

      let url = '';

      if (this.type === 'opportunity') {
        url = '/portal/sale/opportunity/' + task.id + '/updatetaskstage';
      } else if (this.type === 'project') {
        url = '/portal/project/' + task.id + '/updatetaskstage';
      }

      try {
        await axios.post(url, {
          taskId: task.id,
          stageId: newStageId
        });
      } catch (error) {
        console.error('Er is een fout opgetreden bij het bijwerken van de taakstatus:', error);
      }
    },
  }
};
</script>

<style scoped>
.kanban-board {
  display: flex;
  flex-direction: row;
}

.kanban-column {
  flex: 1;
  margin: 0 10px;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
}
</style>